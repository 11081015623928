import { createSSRApp, createApp } from 'vue';
// Router
import { _createRouter } from './router';
// Meta
import { createHead } from '@unhead/vue'
// Store
import store from '@/store';
// Localization i18n
import i18n from "@/localization";
// Globals
import { layoutApp, appRenderer } from '@ozdao/prometheus-framework/modules/globals/client';
// Modules
import globals from '@ozdao/prometheus-framework/modules/globals/client';
import auth from '@ozdao/prometheus-framework/modules/auth/client';
// import backoffice from '@ozdao/prometheus-framework/modules/backoffice/client';
// import organizations from '@ozdao/prometheus-framework/modules/organizations/client';
// import community from '@ozdao/prometheus-framework/modules/community/client';
// import gallery from '@ozdao/prometheus-framework/modules/gallery/client';
import events from '@ozdao/prometheus-framework/modules/events/client';
import ModuleEvents from '@ozdao/prometheus-framework/modules/events/client'; 
// import orders from '@ozdao/prometheus-framework/modules/orders/client';
// import pages from '@ozdao/prometheus-framework/modules/pages/client';
// import products from '@ozdao/prometheus-framework/modules/products/client';
// import spots from '@ozdao/prometheus-framework/modules/spots/client';
// import reports from '@ozdao/prometheus-framework/modules/reports/client';
// Theme
import '@/scss/theme.scss'
// //////////////////////////////////////////////////////
// App Instance Creation
// //////////////////////////////////////////////////////
export function _createApp() {
  let options = {
    messages: {
      "en":{
        meta: {
          "title":"Arman Production - World-Class Concert & Event Organization",
          "description":"Arman Production specializes in organizing exceptional music concerts and events worldwide, from stadium performances to intimate venue shows.",
          "keywords":"Concert Organization, Music Events, Live Performances, Concert Production, Event Management, Music Shows, International Concerts, Professional Events, Concert Planning",
          "og":{
              "title":"Arman Production - World-Class Concert & Event Organization",
              "description":"Arman Production specializes in organizing exceptional music concerts and events worldwide, from stadium performances to intimate venue shows.",
              "image":"/favicon/cover.jpg"
          },
          "twitter":{
              "title":"Arman Production - World-Class Concert & Event Organization",
              "description":"Arman Production specializes in organizing exceptional music concerts and events worldwide, from stadium performances to intimate venue shows.",
              "image":"/favicon/cover.jpg",
              "card":"summary_large_image"
          },
          "htmlAttrs":{
              "lang":"en"
          }
        }
      },
      "ru":{
        meta: {
          "title":"Arman Production - Организация концертов и мероприятий мирового уровня",
          "description":"Arman Production специализируется на организации исключительных музыкальных концертов и мероприятий по всему миру, от стадионных выступлений до камерных шоу.",
          "keywords":"Организация концертов, Музыкальные мероприятия, Живые выступления, Концертный продакшн, Организация мероприятий, Музыкальные шоу, Международные концерты, Концертное планирование",
          "og":{
              "title":"Arman Production - Организация концертов и мероприятий мирового уровня",
              "description":"Arman Production специализируется на организации исключительных музыкальных концертов и мероприятий по всему миру, от стадионных выступлений до камерных шоу.",
              "image":"/favicon/cover.jpg"
          },
          "twitter":{
              "title":"Arman Production - Организация концертов и мероприятий мирового уровня",
              "description":"Arman Production специализируется на организации исключительных музыкальных концертов и мероприятий по всему миру, от стадионных выступлений до камерных шоу.",
              "image":"/favicon/cover.jpg",
              "card":"summary_large_image"
          },
          "htmlAttrs":{
              "lang":"ru"
          }
        }
      }
    }
  }
  const app = process.env.MOBILE_APP ? createApp(layoutApp, options) : createSSRApp(layoutApp, options);

  const router = _createRouter(); 
  const meta = createHead()
  
  const { storeEvents } = ModuleEvents.views.store;

  console.log('event store is', storeEvents)
  store.addStore('events', storeEvents);

  
  globals.initialize(app, store, router)
  auth.initialize(app, store, router)
  // backoffice.initialize(app, store, router)
  // organizations.initialize(app, store, router)
  // community.initialize(app, store, router)
  events.initialize(app, store, router, {
    page_event:  () => import(/* webpackChunkName: 'Event' */ '@/components/pages/Event.vue'),
    // page_event_edit:  () => import(/* webpackChunkName: 'Event' */ '@/components/pages/Event.vue')
  })
  // gallery.initialize(app, store, router)
  // orders.initialize(app, store, router)
  // products.initialize(app, store, router)
  // spots.initialize(app, store, router)
  // pages.initialize(app, store, router)
  // reports.initialize(app, store, router)

  app
    .use(router)
    .use(i18n)
    .use(meta)

  if (process.env.MOBILE_APP)  { 
    app.mount("#app");
  } else {
    return { 
      app, 
      router, 
      store, 
      i18n,
      meta 
    }
  }
};

// FOR SPA
if (typeof window !== 'undefined' && process.env.MOBILE_APP) {
  _createApp();
}

// FOR SSR / CLIENT ENTRY
if (typeof window !== 'undefined' && !process.env.MOBILE_APP) {
  appRenderer.renderAndMountApp({ createApp: _createApp });
}

// FOR SSR / SERVER ENTRY
export async function render({ url, cookies, languages }) {
  return appRenderer.render({
    url,
    cookies,
    createApp: _createApp
  });
}
