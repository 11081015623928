<template>
  <div id="app-wrapper" class="pos-relative">
    <transition name="moveFromTop" appear>
      <Loader v-if="!page" class="pos-fixed"/>
    </transition>

    <header 
      v-if="!MOBILE_APP && route.meta.header.component"
      id="header" 
      class="
        pd-thin 
        gap-micro
        flex-justify-between
        flex-nowrap
        flex
        h-4r
        w-100
        z-index-2 
        pos-relative pos-t-0
      "
      :class="{
        't-black bg-white': headerTheme === 'light',
        't-white bg-black': headerTheme === 'dark'  
      }"
    >
      <component
        ref="header" 
        :is="route.meta.header.component"
        :theme="headerTheme"
        :logotype="route.meta.header.logotype"
        :location="route.meta.header.location"
      >
        <component
          v-if="route.meta.navigation"
          :is="route.meta.navigation"
          class="mn-l-auto desktop-only"
        />
      </component>
    </header>

    <section 
      id="screen" 
      ref="screen"
      class="transition-ease-in-out h-min-100 w-100 pos-relative o-y-scroll o-x-hidden"
    >

      <Suspense>
        <router-view  
          v-slot="{ Component, route }" 
          :class="{'mn-b-extra': MOBILE_APP}"
        >
          <Status 
            v-if="store.globals.state.error.show" 
            :data="store.globals.state.error"
            @close="store.globals.state.error.show = false"
            class="z-index-7" 
          />

          <transition @before-enter="scrollTop" name="scaleTransition" mode="out-in" appear>
            <component 
              ref="page"  
              :is="Component" 
              :key="route.path"
            />
          </transition>

          <!-- <Footer /> -->
        </router-view>
      </Suspense>
     
    </section>
  </div>
</template>



<script setup>
  import { computed, ref, onMounted  } from 'vue';
  // Router
  import { useRoute } from 'vue-router';
  // Store
  // import * as globals from '@pf/src/modules/globals/views/store/globals';
  // Partials
  import { Status, Popup, Loader}   from '@ozdao/prometheus-framework';

  import { useStore } from '@/store';

  const store = useStore()


  /////////////////////////////
  // LOADING
  /////////////////////////////
  function scrollTop(){
    document.getElementById('app').scrollIntoView();
  }
    // State
  let show = ref(false)
  // Preloader
  const page = ref(null)
  /////////////////////////////
  // CREATED
  /////////////////////////////
  const route = useRoute()
  // const router = useRouter()
  // Ref Code
  const referalCode = ref(route.query.referalCode);
  /////////////////////////////
  // Methods
  /////////////////////////////

  onMounted(async () => {
       localStorage.setItem('CapacitorStorage.darkmode', 'true');

// setTheme
// toggleTheme     
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('SW registered: ', registration);
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
     });
    }
    
    if (referalCode.value) {
      localStorage.setItem('referalCode', referalCode.value);
    }

    if (page.value) show.value = true

     await store.globals.actions.setTheme(true);
  });
</script>

<style lang="scss">;  
  .moveFromTop-enter-active,
  .moveFromTop-leave-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease; 
    
  }
  .moveFromTop-enter-from,
  .moveFromTop-leave-to {
    transform: translateY(-1rem);
    opacity: 0;
    transition: all 0.5s ease;
  }

  .moveFromTopAbsolute-enter-active,
  .moveFromTopAbsolute-leave-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease; 
    
  }
  .moveFromTopAbsolute-enter-from,
  .moveFromTopAbsolute-leave-to {
    position: absolute;
    transform: translateY(-1rem);
    opacity: 0;
    transition: all 0.5s ease;
  }
  .ScaleOut-enter-active,
  .ScaleOut-leave-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease; 
    
  }
  .ScaleOut-enter-from,
  .ScaleOut-leave-to {
    opacity: 0;
    transform: scale(0.9);
    transition: all 0.3s ease;
  }
  .slide-fade-enter-active {
    // min-height: 100vh;
    transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slide-fade-leave-active {
    // min-height: 100vh;
    transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    min-height: 0;
    transform: translateX(20px);
    opacity: 0;
    left: 0;
    top: 0;
  }

  .scaleTransition-5px-enter-active,
  .scaleTransition-5px-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.2s ease;
  }

  .scaleTransition-5px-enter-from,
  .scaleTransition-5px-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    transform: translateY(-30px); 
    transition: all 0.2s ease;
  }

  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleTransition-enter-active,
  .scaleTransition-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }
  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleIn-enter-active,
  .scaleIn-leave-active {
    // background: red;
    transition: all 0.5s ease;
    > section,div { transform-origin: 0 0; transform: translateZ(0px);  transition: all 0.5s ease; }
  }
  .scaleIn-enter-from,
  .scaleIn-leave-to {
    opacity: 0;
    transform: scale(0.95);
    > section,div { transform: translateZ(-30px);  transition: all 0.5s ease; }
  }
</style>