<template>
  <router-link :to="{name:'Home Page'}"><img class="h-6r" src="/logo/logo.png"></router-link>

  <button 
    v-if="store.auth.state.user._id "
    aria-label="profile"
    class="pd-thin pos-absolute pos-r-2r pos-t-30 radius-extra cursor-pointer bg-main"
    @click="router.push({ name: 'User Profile', params: { _id: store.auth.state.user._id }})
    "
  >
   Бекофис
  </button>
</template>

<script setup>

  import { useRoute,useRouter } from 'vue-router';
  import { useStore } from '@/store';

  const route = useRoute()
  const router = useRouter()
  const store = useStore()

</script>

<style lang="scss">
  #header {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(var(--black),0.1)
  }
</style>
