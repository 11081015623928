import { createI18n, useI18n } from 'vue-i18n'
// Auth
import SignIn from './auth/SignIn.json' 

const supportedLocales = {
  // en: "🌎 EN",
  ru: "🇷🇺 RU",
  // zh: "🇨🇳 ZH",
  // fr: "🇫🇷 FR",
  // de: "🇩🇪 DE",
  // it: "🇮🇹 IT"
}

// Объединяем все JSON модули в один объект
const messages = {}

// добавляем содержимое каждого языкового словаря в messages
for (const locale of Object.keys(supportedLocales)) {
  messages[locale] = {
    ...((SignIn[locale] || {})),
  }
}

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: "ru-default",
  legacy: false,
  globalInjection: true,
  messages: messages,
  availableLocales: Object.keys(supportedLocales)
})

export default i18n;
