import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
} from 'vue-router';

import { defineAsyncComponent } from 'vue'

import { scrollBehavior, layoutClient, Sidebar } from '@ozdao/prometheus-framework/modules/globals/client';

import Logotype from '@/components/icons/Logotype.vue'
import Navigation from '@/components/partials/Navigation.vue'
import Header from '@/components/partials/Header.vue'

import LayoutClient from '@/components/layout/Client.vue'

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [
  {
   	path: '/:locale?',

    components: {
      default: LayoutClient,
    },
    name: 'Home',

    meta: {
      navigation: Navigation,
      sidebar: Sidebar,
      header: {
        component: Header,
        logotype: Logotype,
        location: false
      },
      walkthrough: null,
    },

    beforeEnter: [
      ...(process.env.MOBILE_APP ? [auth.views.middlewares.validationAuth.initUser] : [])
    ],

    children: [
    	{
        path: '',
        name: 'Home Page',
        meta: {
          title_hide: true,
          header_theme: 'dark',
          breadcrumbs: {
            hide: true
          },
        },
        component: () => import(/* webpackChunkName: 'Home' */ '@/components/pages/Home.vue'),
      },{
        name: 'About',
        path: 'about',
        component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/pages/Contacts.vue'),
      },{
        name: 'Contacts',
        path: 'contacts',
        component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/pages/Contacts.vue'),
      }
    ]
  }
];

export function _createRouter() {
  return createRouter({
    routes,
    history,
    scrollBehavior
  });
}